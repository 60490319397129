<template>
  <v-chip
    close
    outlined
    @click.stop
    @click:close="$emit('remove', true)">
    <span class="fs-42">
      {{ name }}
    </span>
  </v-chip>
</template>
<script>
export default {
  name: 'SearchFilterInputBool',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>